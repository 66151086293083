import React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import readingTime from "reading-time"
import DearDesignerFavicon from "../../../images/favicon.png"
import { Link } from "gatsby"

const BlogSlide = ({ featuredImage, title, author, authorImage, date, content, category, uri }) => {
  const READING_TIME = content && Math.round(readingTime(content).minutes)

  const imageStyle = 'w-full h-[311px] sm:h-[262px] mb-[28px] sm:mb-[38px]'

  const renderImage = () => {
    if(featuredImage?.node?.localFile){
      return (
        <GatsbyImage
          image={getImage(featuredImage.node.localFile)}
          alt={title}
          className={imageStyle}
        />
      )
    }

    return (
      <StaticImage
        src="../../../images/post-preview.jpg"
        placeholder="blurred"
        className={imageStyle}
        alt={title}
      />
    )
  }

  return (
    <article className='relative'>
      <span className='md-max:hidden top-4 left-4 absolute px-4 py-2 text-sm leading-none tracking-[0.105em] text-dd-black-80 bg-dd-black-10 bg-opacity-70 uppercase rounded-[3px] z-10'>
        {category}
      </span>
      <Link to={uri}>
      {renderImage()}
      </Link>
      <p className='mt-0 sm:hidden flex items-center justify-between text-dd-black-60 text-[10px] uppercase tracking-[0.095em] mb-[28px]'>
        {date && <span>{date}</span>}
        {content && <span>{READING_TIME} Mins Read</span>}
      </p>
      <header className="flex sm:justify-between sm:mb-[30px] items-start sm-max:flex-col">
        <Link to={uri} className='text-dd-black-60 text-2xl leading-[29px] sm:text-3xl sm:leading-[38px] sm:max-w-[470px] font-medium sm-max:mb-[30px]'>
          {title}
        </Link>
        <p className='flex items-center no-underline mt-0'>
          <img
            src={author !== "DearDesigner" ? authorImage : DearDesignerFavicon}
            alt={author}
            className='w-[25px] h-[25px] md:w-[31px] md:h-[31px] rounded-[50%] mr-[6px] sm:mr-2'
          />
          <span className='text-dd-black-60 text-xs sm:text-sm leading-[21px]'>
            {author}
          </span>
        </p>
      </header>
      <footer className='hidden sm:flex items-center gap-x-[68px] text-dd-black-60 text-sm uppercase tracking-[0.095em]'>
        {date && <span>{date}</span>}
        {content && <span>{READING_TIME} Mins Read</span>}
      </footer>
    </article>
  )
}

export default BlogSlide