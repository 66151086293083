import React from "react"

const ArrowRightLargeIcon = (props) => {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M21.1148 0.839141L41.501 21.2254L21.1148 41.6116" stroke="#B6B6B6"/>
    </svg>
  )
}

export default ArrowRightLargeIcon