import React from "react"

const ArrowRightShortIcon = (props) => {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0.999993 5.76844H10.6182" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.80908 0.959351L10.6182 5.76844L5.80908 10.5775" stroke="black" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowRightShortIcon