import React from "react"
import PagesLayout from "../components/layout/pages"
import BlogSlide from "../components/pages/blog/blog-slide"
import { graphql, useStaticQuery } from "gatsby"
import ArrowRightLargeIcon from "../icons/arrow-right-large"
import ArrowLeftLargeIcon from "../icons/arrow-left-large"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper"

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "./blog.css"
import BlogCategory from "../components/pages/blog/blog-category"

SwiperCore.use([Navigation, Pagination, Autoplay])

const Blog = () => {
  const categoriesQuery = useStaticQuery(graphql`
    {
      allWpCategory(filter: { slug: { nin: ["uncategorized","events"] } }) {
        nodes {
          name
          slug

          posts {
            nodes {
              uri
              title
              slug
              date(formatString: "DD MMMM, YYYY")
              content
              
              extraPostDetails{
                author
                authorImage {
                  mediaItemUrl
                }
                authorLink
              }
              
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const categoriesData = categoriesQuery.allWpCategory.nodes.filter(node => node.posts.nodes.length > 0);

  const carouselPosts = categoriesData.map(category => ({
    ...category.posts.nodes[0],
    category: category.name
  }));

  const navigationButtonStyle = 'md-max:hidden py-[18px] bg-white bg-opacity-70 absolute top-[149px] z-10 disabled:opacity-0 disabled:pointer-events-none';

  return (
    <PagesLayout disablePadding title='Blog'>
      <header className='md:px-[76px] pt-[28px] md:pt-[57px] mb-[60px] sm:mb-20 grid relative'>
        <Swiper
          slidesPerView={1}
          navigation={{
            prevEl: '.prev-slide-button',
            nextEl: '.next-slide-button'
          }}
          autoHeight
          breakpoints={{
            640: {
              autoHeight: false
            }
          }}
          autoplay={{
            delay: 3000
          }}
          loop
        >
          {
            carouselPosts.map(post => (
              <SwiperSlide key={`large-${post.slug}`}>
                <BlogSlide
                  category={post.category}
                  title={post.title}
                  author={post.extraPostDetails.author || "DearDesigner"}
                  authorImage={post.extraPostDetails.authorImage?.mediaItemUrl}
                  content={post.content}
                  date={post.date}
                  featuredImage={post.featuredImage}
                  uri={post.uri}
                />
              </SwiperSlide>
            ))
          }
        </Swiper>
        <button className={`prev-slide-button ${navigationButtonStyle} left-[76px] pl-4 pr-2`}>
          <ArrowLeftLargeIcon/>
        </button>
        <button className={`next-slide-button ${navigationButtonStyle} right-[76px] pl-2 pr-4`}>
          <ArrowRightLargeIcon/>
        </button>
      </header>
      {categoriesData.map(category => <BlogCategory category={category}/>)}
    </PagesLayout>
  )
}

export default Blog