import React from "react"

const ArrowLeftLargeIcon = (props) => {
  return (
    <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2_2)">
        <path d="M21.8852 41.1609L1.49899 20.7746L21.8852 0.388402" stroke="#B6B6B6"/>
      </g>
      <defs>
        <clipPath id="clip0_2_2">
          <rect width="43" height="42" fill="white" transform="translate(43 42) rotate(-180)"/>
        </clipPath>
      </defs>
    </svg>
  )
}

export default ArrowLeftLargeIcon