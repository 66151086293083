import React from "react"
import { Link } from "gatsby"
import ArrowRightShortIcon from "../../../icons/arrow-right-short"
import DearDesignerFavicon from "../../../images/favicon.png"

const BlogCategory = ({ category }) => {
  const posts = category.posts.nodes.slice(0, 3)

  const renderArticle = (post) => {
    const author = post.extraPostDetails.author || "DearDesigner"
    const authorImage = post.extraPostDetails.authorImage?.mediaItemUrl

    return (
      <article key={post.slug}>
        <span className="text-[10px] leading-[21px] text-dd-black-60 uppercase tracking-[0.095em]">
          {post.date}
        </span>
        <Link to={post.uri} className="block text-base leading-[24px] mt-2 mb-3 sm:mb-5 text-dd-black-60">
          {post.title}
        </Link>
        <p className="flex items-center no-underline mt-0">
          <img
            src={author !== "DearDesigner" ? authorImage : DearDesignerFavicon}
            alt={author}
            className="w-[27px] h-[27px] rounded-[50%] mr-[10px] sm:mr-2"
          />
          <span className="text-dd-black-60 text-sm leading-[21px]">
                      {author}
                    </span>
        </p>
      </article>
    )
  }

  if (posts.length > 0) {
    return (
      <section className="md:pl-[76px] md:pr-[36px] py-[60px] sm:pt-[44px] sm:pb-[68px] odd:bg-white"
               key={category.slug}>
        <Link
          to={`/${category.slug}`}
          className="rounded-[3px] border-dd-black-60 border-[0.5px] px-4 py-[10px] mb-6 sm:mb-[30px] inline-flex items-center no-underline uppercase"
        >
            <span className="inline-block mr-[10px] tracking-[0.105em] text-xs leading-none">
              {category.name}
            </span>
          <ArrowRightShortIcon />
        </Link>
        <div className="grid md:grid-cols-3 gap-y-[54px] gap-x-[94px]">
          {posts.map(renderArticle)}
        </div>
      </section>
    )
  }

  return null
}

export default BlogCategory